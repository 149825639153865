<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="6.667" viewBox="0 0 10 6.667">
    <path class="a" d="M5,6.667,0,0H10Z" />
  </svg>
</template>

<script>
// TODO: This icon is not used anywhere
export default {
  props: {
    height: {
      type: String,
      default: '18',
    },
    width: {
      type: String,
      default: '15',
    },
    color: {
      type: String,
      default: 'primary-grey',
    },
  },
  computed: {
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
